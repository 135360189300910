/*global $ fbq*/

if (typeof fbq === 'function') {
  $(document).on('click', '.fbq-main-search-btn', function() {
    fbq('track', 'Search')
  })

  $(document).on('click', '.fbq-registration-btn', function() {
    fbq('track', 'CompleteRegistration')
  })

  $(document).on('click', '.fbq-login-btn', function() {
    fbq('track', 'ViewContent')
  })

  $(document).on('click', '.fbq-track-lead', function() {
    fbq('track', 'Lead')
  })

  // for webitel
  $(document).on('click', '.call-me-btn', function() {
    fbq('track', 'Lead')
  })

  $(document).on('click', '.fbq-for-owners-form-btn', function() {
    fbq('track', 'InitiateCheckout')
  })
}