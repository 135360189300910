/*global $ gon I18n initMarkers NProgress initPhoneInput LazyLoad initQuickOrderFormValidator ajustFixedElements*/
if (typeof require === 'function') {
  var ResponsiveBootstrapToolkit = require('responsive-toolkit/dist/bootstrap-toolkit')
}

$(document).on('focus', '.input-car-name-search', function() {
  var car = $(this).data('car-brand') || ''
  
  $(this)
    .searchcomplete({
      source: function(request, response) {
        var station_id = this.element.context.dataset.stationId,
          jqxhr

        if (typeof station_id !== 'undefined') {
          request.station_id = station_id
        }

        if (jqxhr) {
          return
        }
        
        if (typeof(gon.filterBrandId) != 'undefined'){
          request.filter_brand_id = gon.filterBrandId
        }

        jqxhr = $.getJSON('/api/brands/search', request, function(data) {
          response(data)
        }).complete(function() {
          jqxhr = null
        })
      },
      select: function(event, ui) {
        var $this = $(this)
        car = ui.item.label || ui.item.brand

        // Save selected car
        $this.data('car-brand-id', ui.item.brand_id)
        $this.data('car-brand-model-id', ui.item.brand_model_id)
        $this.data('car-brand-slug', ui.item.slug)
        $this.data('car-brand', car)

        if ($(this).hasClass('certificate')) {
          window.refreshCertificateStationsList($this)
        }

        if (!$(this).hasClass('no-auto-update')) {
          window.updateStations($(this).closest('form'))
        }
      },
      minLength: 0,
      search: function() {
        $(this)
          .parents('.ui-front')
          .toggleClass('ui-loading', true)
      },
      response: function() {
        $(this)
          .parents('.ui-front')
          .toggleClass('ui-loading', false)
      }
    })
    .click(function(e) {
      var $self = $(this)
      if (
        $self
          .parents('.ui-front')
          .find('.ui-autobooking')
          .css('display') != 'block' &&
        $self.is(':focus')
      ) {
        $self.searchcomplete('search', window.extractLast(e.target.value))
      }
    })
    .on('keyup', function() {
      var carName = $.trim(this.value),
        $this = $(this)

      if (typeof(gon.filterBrandName) != 'undefined'){
        if (carName.match(gon.filterBrandName) == null){
          $this.val(gon.filterBrandName)
          carName = $.trim($this.val())
        }
      }

      if ($(this).hasClass('certificate') && carName != car && car) {
        car = ''
        $(this).data('car-brand-id', '')
        window.refreshCertificateStationsList($this)
      }

      if (!$(this).hasClass('no-auto-update') && carName != car && car) {
        car = ''
        $(this).data('car-brand-slug', '')
        window.updateStations($(this).closest('form'))
      }
    })
})

window.refreshCertificateStationsList = function(form) {
  var certificate_code = $('#certificate_code').val(),
    brand_id = form.data('car-brand-id'),
    real_date = $('#real_date')
      .next('input:hidden')
      .val()

  $.ajax({
    type: 'GET',
    url: '/certificates/' + certificate_code,
    data: {
      brand_id: brand_id,
      real_date: real_date
    },
    dataType: 'json',
    success: function(data) {
      gon.stations = data.update_gon.stations
      gon.action_params = data.update_gon.action_params

      var stationsList = ''
      $.each(data.stations, function(i, elem) {
        stationsList += certificateStationPartial(
          elem,
          data.current_name,
          data.current_phone,
          data.call_me_modes,
          data.car_name,
          data.certificate_code
        )
      })
      $('#stations').html(stationsList)

      window.initCertificatesMarkers(gon.stations)
      new LazyLoad()
    }
  })
}

function certificateStationPartial(station, currentName, currentPhone, callMeModes, carName, certificateCode) {
  var promotion_color = station.promotion_color ? 'promo-color' : ''

  var stationServices = ''
  if ((gon.action_params['services'] || gon.action_params['problems']) && station.station_services) {
    $.each(station.station_services, function(i, elem) {
      stationServices +=
        '<ul class="matched-service">' +
        '<li>' +
        '<span class="name">' +
        elem.name +
        ':' +
        '</span>' +
        '<span class="price">' +
        '<button name="button" type="submit" class="btn btn-primary certificate-select" method="patch" remote="true" data-station="' +
        station.id +
        '"data-certificate="' +
        certificateCode +
        '"data-brand="true">' +
        I18n.t('certificate.js.select') +
        '</button>' +
        '</span>'
    })
  }

  var result =
    '' +
    '<div class="clearfix station station-' +
    station.id +
    ' ' +
    promotion_color +
    '" id=' +
    station.id +
    '>' +
    '<div class="photo">' +
    (station.small_cover_image_url
      ? '<img alt="' +
        station.small_image_alt +
        '" title="' +
        station.small_image_alt +
        '" src="/" data-src="' +
        station.small_cover_image_url +
        '" />'
      : '') +
    '<span class="top-sticker text-center text-uppercase">' +
    I18n.t('station_promotion.js.top') +
    '</span>' +
    '</div>' +
    '<div class="title">' +
    station.type_and_name +
    '</div>' +
    '<div class="address">' +
    '<i class="fa fa-map-marker"></i> ' +
    station.distance_with_measure +
    station.full_address +
    '</div>' +
    '<div class="matched-services">' +
    stationServices +
    '</div>' +
    additionalServicesPartial(station) +
    '</div>'

  return result
}

window.stationsSearch = function(href) {
  $.ajax({
    url: href,
    dataType: 'json',
    success: function(data) {
      // Update gon
      gon.user.lat = data.update_gon.user.lat
      gon.user.lng = data.update_gon.user.lng
      gon.user.distance = data.update_gon.user.distance
      gon.action_params = data.update_gon.action_params
      gon.stations = data.update_gon.stations
      gon.stations_trimmed = data.update_gon.stations_trimmed

      // Update title
      $('title').text(data.search_title)

      // Update stations count
      $('.found').html(data.stations_total_count)

      // Seo script
      $('#heading_seo_script_container').html(data.views.heading_seo_script)

      $('#seo_problem_description_container').html(data.views.seo_service_synonym_description)

      // Top stations
      var topStationsList = ''
      $.each(data.top_stations, function(i, elem) {
        topStationsList += stationPartial(
          elem,
          data.current_name,
          data.current_phone,
          data.call_me_modes,
          data.car_name,
          data.brand_id,
          data.brand_model_id
        )
      })
      $('.promo-top-stations').html(topStationsList)

      // Update breadcrumbs
      $('#breadcrumbs').html(data.views.breadcrumbs)

      // Update time select
      $('.field.time').html(data.views.time_select)

      // Update lang switcher
      $('.language-bar').html(data.views.lang_switcher)

      // Stations list
      var stationsList = ''
      $.each(data.stations, function(i, elem) {
        stationsList += stationPartial(elem, data.current_name, data.current_phone, data.call_me_modes, data.car_name)
      })
      $('#stations').html(stationsList)

      // Update search result blank
      $('.no-station-container').html(noStationPartial(data.no_station))

      // Scroll to top
      var body = $('html, body')
      body.stop().animate({ scrollTop: 0 }, '500', 'swing')

      // Send city slug
      if (!(gon.selected_services.length == 1 && gon.selected_services[0]['landing'])) {
        $('input[name="locale_city_slug"]').val(data.city_slug)
      }

      $('.landing-header').remove()

      // Update ad block
      var ad_blocks = data.views.ad_blocks
      if (ad_blocks.top) {
        $('#main-content > .stations').before(ad_blocks.top)
      }

      if (ad_blocks.bottom) {
        $('#main-content').after(ad_blocks.bottom)
      }
      
      // Update seo block
      var seo_block = data.views.seo_block

      if (seo_block) {
        switch (data.seo.block.position) {
          case 'bottom':
            $('#main-content').after(seo_block)
            break
          case 'top':
            $('#main-content > .stations').before(seo_block)
            break
        }
      }

      initQuickOrderFormValidator('.quick-order-form')

      // Update robots
      var meta_robots = $('meta[name=robots]')
      var robots_content = data.robots

      if (robots_content) {
        if (meta_robots.length == 0) {
          $('<meta name="robots" content="' + robots_content + '"/>').insertAfter($('meta[name=theme-color]').first())
        }
      } else {
        meta_robots.remove()
      }

      // Update affix
      window.initAffix()

      // Adjust margins and top
      ajustFixedElements()
      $('.modal-fullscreen').on('hidden.bs.modal', function() {
        ajustFixedElements()
      })

      // Update map
      if (ResponsiveBootstrapToolkit.is('>=sm')) {
        initMarkers(gon.stations)
      }

      // Update preloader
      NProgress.done()

      // Close modal
      $('#searchModal').modal('hide')

      // Masks for tel
      $('#stations-list input[type=tel]').each(function(index, input) {
        initPhoneInput(input)
      })

      // Tooltips
      $('.stations').tooltip({
        selector: '.station .services li[data-toggle=tooltip]'
      })

      new LazyLoad()

      window.slider.setAttribute('max', Math.max(gon.action_params.distance * 2, 8))
      window.slider.setValue(gon.action_params.distance)
      if (
        gon.action_params.search_by_map ||
        gon.action_params.geo_types == 'neighborhood' ||
        gon.action_params.geo_types == 'country' ||
        gon.action_params.geo_types == 'aal1'
      ) {
        window.slider.disable()
      } else {
        window.slider.enable()
      }
    }
  })
}

function stationPartial(station) {
  var promotion_color = station.promotion_color ? 'promo-color' : '',
    favorite = 'favorite' + (station.in_favorite_list ? ' active' : ''),
    favoriteIcon = station.in_favorite_list ? 'fa-bookmark' : 'fa-bookmark-o ga-favorite-bookmark-btn',
    promotionAction = station.promotion_action_desc ? promotionActionPartial(station) : ''

  var stationServices = ''
  if ((gon.action_params['services'] || gon.action_params['problems']) && station.station_services) {
    $.each(station.station_services, function(i, elem) {
      stationServices += stationServicePartial(elem)
    })
  }

  var stationStyles = ''
  if (gon.selected_styles) {
    $.each(station.matched_styles, function(i, style_name) {
      stationStyles += stationMatchedStylesPartial(style_name)
    })
  }

  var result =
    '' +
    '<div class="clearfix station station-' +
    station.id +
    ' ' +
    promotion_color +
    '" id=' +
    station.id +
    '>' +
    '<div class="' +
    favorite +
    '" data-id="' +
    station.id +
    '">' +
    '<i class="fa ' +
    favoriteIcon +
    '"></i>' +
    '</div>' +
    '<div class="photo">' +
    (station.small_cover_image_url
      ? '<img alt="' +
        station.small_image_alt +
        '" title="' +
        station.small_image_alt +
        '" src="/" data-src="' +
        station.small_cover_image_url +
        '" />'
      : '') +
    '<span class="top-sticker text-center text-uppercase">' +
    I18n.t('station_promotion.js.top') +
    '</span>' +
    '</div>' +
    '<a target="_blank" class="clearfix" href="' +
    station.path +
    '">' +
    '<div class="title">' +
    promotionAction +
    station.type_and_name +
    '</div>' +
    '</a>' +
    '<div class="address">' +
    '<i class="fa fa-map-marker"></i> ' +
    station.distance_with_measure +
    station.full_address +
    '</div>' +
    '<div class="matched-services">' +
    stationServices +
    '</div>' +
    '<div class="matched-styles">' +
    stationStyles +
    '</div>' +
    servicesPartial(station.services) +
    ratingPartial(station) +
    additionalServicesPartial(station) +
    quickOrderButtonPartial(station.order_form_active, station.order_exists_for) +
    station.views.quick_order_form +
    '</div>'

  return result
}

function stationMatchedStylesPartial(style_name) {
  return `<ul class="matched-style"><li><span class="name">${style_name}</span></li></ul>`
}

function stationServicePartial(station_service) {
  return (
    '' +
    '<ul class="matched-service">' +
    '<li>' +
    '<span class="name">' +
    station_service.name +
    ':' +
    '</span>' +
    '<span class="price">' +
    station_service.view_price +
    '</span>' +
    '</li>' +
    '</ul>'
  )
}

function promotionActionPartial(station) {
  return (
    '' +
    '<span data-toggle="tooltip" title="' +
    station.promotion_action_desc +
    '" data-placement="bottom" class="text-primary text-uppercase">' +
    '<i class="fa fa-gift" aria-hidden="true"></i>' +
    I18n.t('station_promotion.js.promo') +
    '</span>'
  )
}

function servicesPartial(services) {
  if (services && services.length) {
    var srv = ''
    $.each(services, function(i, service) {
      if (service.image_url && service.seo_block_url) {
        srv +=
          '<li data-toggle="tooltip" data-container="body" title="' +
          service.name +
          '" data-placement="top">' +
          '<a href="' +
          service.seo_block_url +
          '">' +
          '<img src="' +
          service.image_url +
          '">' +
          '</a>' +
          '</li>'
      } else if (service.image_url) {
        srv +=
          '<li data-toggle="tooltip" data-container="body" title="' +
          service.name +
          '" data-placement="top">' +
          '<img src="' +
          service.image_url +
          '">' +
          '</li>'
      }
    })
    return '' + '<div class="services">' + '<ul class="list-inline list-unstyled">' + srv + '</ul>' + '</div>'
  } else {
    return ''
  }
}

function ratingPartial(station) {
  if (station.rating > 0 || station.reviews_count > 0) {
    var rating = ''
    var reviews = ''

    if (station.rating > 0) {
      rating += '<span class="stars">' + station.rating + ' <i class="fa fa-star"></i>' + '</span>'
    }

    if (station.reviews_count > 0) {
      reviews = ' <a target="_blank" href="' + station.path + '#ratings">' + station.comments_count + '</a>'
    }

    return '<div class="rank">' + rating + reviews + '</div>'
  } else {
    return ''
  }
}

function additionalServicesPartial(station) {
  if (station.additional_services && station.additional_services.length > 0) {
    var services = ''
    $.each(station.additional_services, function(i, service) {
      services +=
        '<li data-toggle="tooltip" data-container="body" title="' +
        service.name +
        '" data-placement="top">' +
        '<img src="' +
        service.icon_url +
        '" alt="' +
        service.name +
        '">' +
        '</li>'
    })
    var result = ''
    if (station.services && station.services.length > 2) {
      result += '<br>'
    }
    return result + '<div class="services">' + '<ul class="list-inline list-unstyled">' + services + '</ul>' + '</div>'
  } else {
    return ''
  }
}

function quickOrderButtonPartial(order_form_active, order_exists_for) {
  var result = ''
  if (order_form_active && !order_exists_for) {
    result =
      '' +
      '<div class="quick-order pull-right">' +
      '<div class="btn btn-sm btn-qo ga-quick-order ga-station-list-quick-order-btn">' +
      I18n.t('js.quick_order') +
      '</div>' +
      '</div>'
  }

  return result
}

function noStationPartial(noStation) {
  var result = ''

  if (noStation) {
    result =
      '' +
      '<div class="row">' +
      '<div class="col-xs-12 text-center">' +
      '<div class="no-station">' +
      noStation +
      '</div>' +
      '</div>' +
      '</div>'
  }

  return result
}

$(function() {
  $(window).scroll(function() {
    if (ResponsiveBootstrapToolkit.is('>=sm')) {
      return
    }

    var $headerList = $('.found.pull-left, #breadcrumbs, .landing-header.landing-top'),
      $stations = $('.stations')

    if ($(this).scrollTop() > 0) {
      if ($(this).scrollTop() > 10) {
        $headerList.slideUp(50, function() {
          $stations.css('margin-top', 0)
        })
      } else {
        $headerList.slideUp(250, function() {
          $stations.css('margin-top', 0)
        })
      }
    } else {
      $headerList.slideDown(250, function() {
        $stations.css('margin-top', $('#breadcrumbs').outerHeight())
      })
    }
  })
})
