/*global $*/
if (typeof require === 'function') {
  var Cookies = require('js-cookie')
}

var auth_user = Cookies.get('auth_user', { path: '' }),
  auth_help_links = Cookies.get('auth_help_links', { path: '' })
if (auth_user) {
  localStorage.setItem('user', auth_user)
  localStorage.setItem('helpLinks', auth_help_links)
  Cookies.remove('auth_token')
  Cookies.remove('auth_user')
  Cookies.remove('auth_help_links')
}

$('.topbar-profile .logout').click(function() {
  localStorage.removeItem('user')
  localStorage.removeItem('helpLinks')
})