/*global $ moment gon I18n _*/
if (typeof require === 'function') {
  var Cookies = require('js-cookie')
}

$(function() {
  var timeInterval = 30000
  var startHandleNotifications = function() {
    var lastUpdateTime = moment(Cookies.get('notifications_updated'))
    var update_notifications = true
    if (lastUpdateTime.isValid() && lastUpdateTime <= moment().add(-timeInterval, 'milliseconds')) {
      $.ajax({
        dataType: 'json',
        type: 'GET',
        url: '/api/users/notifications',
        success: function(data) {
          window.setCookie('notifications_updated', moment().toISOString(), { path: '/' })
          startShowingNotifications(data)
          update_notifications = data.update_notifications
        },
        complete: function() {
          if (update_notifications) {
            setTimeout(startHandleNotifications, timeInterval)
          }
        }
      })
    } else {
      setTimeout(startHandleNotifications, timeInterval)
      if (Cookies.get('notifications_updated') == null) {
        window.setCookie('notifications_updated', moment().toISOString(), { path: '/' })
      }
    }
  }

  function startShowingNotifications(notifications) {
    if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      showNotifications(notifications)
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function(permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          showNotifications(notifications)
        }
      })
    }
  }

  function showNotifications(notifications) {
    if (notifications.approved_services) {
      $.each(notifications.approved_services, function(index, notification) {
        spawnNotification(notification.notification_approve_event, notifications.icon, notification.name, null)
        $.ajax({
          dataType: 'json',
          type: 'PATCH',
          url: '/api/services/' + notification.id + '/notification_is_showen'
        })
      })
    }

    if (notifications.deleted_services) {
      $.each(notifications.deleted_services, function(index, notification) {
        spawnNotification(notification.notification_delete_event, notifications.icon, notification.name, null)
        $.ajax({
          dataType: 'json',
          type: 'PATCH',
          url: '/api/services/' + notification.id + '/notification_is_showen'
        })
      })
    }

    if (notifications.reminders) {
      $.each(notifications.reminders, function(index, notification) {
        notification.service = notification.service === undefined ? {} : notification.service
        spawnNotification(notification.comment, notifications.icon, notification.name, notification.service.get_path)
        $.ajax({
          dataType: 'json',
          type: 'PATCH',
          url: '/api/reminders/' + notification.id + '/notification_is_showen'
        })
      })
    }

    if (notifications.orders) {
      $.each(notifications.orders, function(index, notification) {
        var template =
          I18n.t('order_modal.js.order_number') +
          ' {{ id }} - {{ status }}' +
                  '{{# if(services) { }}' +
          I18n.t('order_modal.js.services') +
          ': {{ services_names }}' +
                '{{# } }}'

        // Config underscore templates
        _.templateSettings = {
          evaluate: /\{\{#([\s\S]+?)\}\}/g, // {{# console.log("blah") }}
          interpolate: /\{\{[^#{]([\s\S]+?)[^}]\}\}/g, // {{ title }}
          escape: /\{\{\{([\s\S]+?)\}\}\}/g // {{{ title }}}
        }

        template = _.template(template)
        var comment = template({
          id: notification.id,
          status: notification.status_i18n,
          services: !!notification.services.length,
          services_names: notification.services
            .map(function(x) {
              return x.name
            })
            .join(', ')
        })
        notification.station = notification.station === undefined ? {} : notification.station
        spawnNotification(
          comment,
          notifications.icon,
          notification.station.type_and_name,
          notification.station.get_path
        )
        $.ajax({
          dataType: 'json',
          type: 'PATCH',
          url: '/api/front/v1/orders/' + notification.id + '/notification_is_showen'
        })
      })
    }
  }

  function spawnNotification(theBody, theIcon, theTitle, theLink) {
    var options = {
      body: theBody,
      icon: theIcon
    }
    var n = new Notification(theTitle, options)

    if (theLink) {
      n.onclick = function() {
        window.open(theLink, '_blank')
      }
    }

    setTimeout(n.close.bind(n), timeInterval)
  }

  if (gon.user_authenticted) {
    startHandleNotifications()
  }
})
