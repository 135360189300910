/*global gon $ I18n initPhoneInput moment PhotoSwipe PhotoSwipeUI_Default*/

$(function() {
  $('input[type=tel]').each(function(index, input) {
    initPhoneInput(input)
  })
})

if (typeof require === 'function') {
  var MobileDetect = require('mobile-detect'),
    Cookies = require('js-cookie'),
    URI = require('urijs'),
    GoogleMapsLoader = require('google-maps'),
    autocomplete = require('jquery-ui/ui/widgets/autocomplete'),
    datepicker = require('jquery-ui/ui/widgets/datepicker')
} else {
  autocomplete = $.ui.autocomplete
  datepicker = $.datepicker
}

var md = new MobileDetect(window.navigator.userAgent)

// Datepicker default locale
datepicker.setDefaults(datepicker.regional[gon.locale == 'ua' ? 'uk' : gon.locale || 'ru'])

// Popovers
$('[data-toggle="popover"]').popover()

$('#searchModal')
  .on('shown.bs.modal', function() {
    $('body').addClass('search-modal-open')
  })
  .on('hidden.bs.modal', function() {
    $('body').removeClass('search-modal-open')
    // Fix mobile filters affix
    if (md.mobile() && $('.filters').css('width') == '0px') {
      $('.filters').css('width', 'auto')
    }
  })

// Selectpicker
if ($.isFunction($.fn.selectpicker)) {
  $('.selectpicker').selectpicker({
    iconBase: 'fa',
    tickIcon: 'fa-check',
    noneSelectedText: I18n.t('js.noneSelectedText'),
    container: 'body'
  })
}

// Datetimepicker
if ($.isFunction($.fn.datetimepicker)) {
  $('.datetimepicker').datetimepicker({
    format: I18n.t('date.js.eu_date_time'),
    timeZone: Cookies.get('time_zone'),
    defaultDate: moment(),
    sideBySide: true,
    allowInputToggle: true,
    icons: {
      time: 'fa fa-clock-o',
      date: 'fa fa-calendar',
      up: 'fa fa-arrow-up',
      down: 'fa fa-arrow-down',
      previous: 'fa fa-arrow-left',
      next: 'fa fa-arrow-right'
    },
    locale: gon.locale == 'ua' ? 'uk' : gon.locale || 'ru'
  })
}

// Modal fullscreen
$('.modal-fullscreen').on('show.bs.modal', function() {
  setTimeout(function() {
    $('.modal-backdrop').toggleClass('modal-backdrop-fullscreen', true)
    $('body').toggleClass('modal-fullscreen-open', true)
  }, 0)
})

$('.modal-fullscreen').on('hidden.bs.modal', function() {
  $('.modal-backdrop').toggleClass('modal-backdrop-fullscreen', false)
  $('body').toggleClass('modal-fullscreen-open', false)
})

$.widget('custom.searchcomplete', autocomplete, {
  _create: function() {
    this._super()
    this.widget().menu('option', 'items', '> :not(.ui-autocomplete-category)')
  },
  _renderMenu: function(ul, items) {
    var that = this,
      currentCategory = ''
    $.each(items, function(index, item) {
      if (item.category_name && item.category_name != currentCategory) {
        var $headerLi = $('<li class="ui-autocomplete-category category-header">' + item.category_name + '</li>')
        currentCategory = item.category_name
        switch (item.category_code) {
          case 'stations':
            $headerLi.prepend('<i class="fa fa-cogs"></i>')
            break
          case 'services':
            $headerLi.prepend('<i class="fa fa-wrench"></i>')
            break
          case 'symptoms':
            $headerLi.prepend('<i class="fa fa-stethoscope"></i>')
            break
          case 'problems':
            $headerLi.prepend('<i class="fa fa-exclamation-triangle"></i>')
            break
          case 'styles':
            $headerLi.prepend('<i class="fa fa-palette"></i>')
            break
        }
        ul.append($headerLi)
      }
      var li = that._renderItemData(ul, item)
      if (item.category_name) {
        li.attr('aria-label', item.category_name + ' : ' + item.label)
      }
      li.addClass(item.category_code)
      ul.addClass('ui-autobooking')
    })
  },
  _renderItem: function(ul, item) {
    var that = this,
      rendered_item,
      bindedInput = that.bindings.first()
      
    if (item.category_code == 'stations') {
      var services = bindedInput.data('serviceSlugs') ? '/s-' + bindedInput.data('serviceSlugs') : '',
        item_url = /^http(s)?:\/\//.test(item.value) ? item.value : '/' + item.value
      rendered_item = $(
        '<li><a href="' + item_url + services + '" target="_blank">' + item.label + '</a></li>'
      )
        .data('item.autocomplete', item)
        .appendTo(ul)
    } else {
      rendered_item = $('<li></li>')
        .data('item.autocomplete', item)
        .append(item.label)
        .appendTo(ul)
    }
    return rendered_item
  }
})

// Set empty message for autocomplete
$('body').on('searchcompleteresponse', '.ui-autocomplete-input', function(event, ui) {
  if (!ui.content.length && this.id !== 'reminder_name') {
    ui.content.push({ value: '', label: I18n.t('station.js.station_search_no_result'), category_code: 'info' })
  }
})

function split(val) {
  val = val || ''
  return val.toString().split(/,\s*/)
}

window.extractLast = function(term) {
  return split(term).pop()
}

$(document).ready(function() {
  // GENERAL
  $('[data-toggle="tooltip"]').tooltip()

  $(document).on('click', '.select-on-click', function(e) {
    $(e.target).select()
  })

  $(document).on('click', '.direction', function(e) {
    var $this = $(this),
      lat = $this.data('lat'),
      lng = $this.data('lng')
    if (lat & lng) {
      if (Cookies.get('lat_lng')) {
        e.preventDefault()
        var user_lat = parseFloat(Cookies.get('lat_lng').split(',')[0])
        var user_lng = parseFloat(Cookies.get('lat_lng').split(',')[1])
        window.open('https://www.google.com/maps/dir/' + user_lat + ',' + user_lng + '/' + lat + ',' + lng)
      } else {
        window.open('https://www.google.com/maps?q=' + lat + ',' + lng + '&z=14')
      }
    }
  })

  // HOME PAGE
  $('.btn-collapse').on('click', function() {
    $(this)
      .parents('.tab-pane')
      .removeClass('active')
    $(this)
      .parents('.popular')
      .find('.popular-block-content')
      .removeClass('active')
  })

  $('a.view-all-btn[data-toggle="tab"]').on('shown.bs.tab', function(e) {
    $('.popular .popular-block-content').removeClass('active')
    $(e.target)
      .parents('.popular-block-content')
      .addClass('active')

    // Scroll to tab
    var href = $(this).attr('href')
    $('html, body').animate(
      {
        scrollTop: $(href).offset().top - 150
      },
      300
    )
  })

  $('.btn-collapse-toogle').on('click', function() {
    $(this)
      .parents('.popular-collapse')
      .collapse('hide')
  })

  $('.popular-collapse').on('shown.bs.collapse', function(e) {
    $('.popular .popular-block-content').removeClass('active')
    $(e.target)
      .parents('.popular-block-content')
      .addClass('active')
  })

  $('.popular-collapse').on('hidden.bs.collapse', function() {
    $('.popular .popular-block-content').removeClass('active')
  })

  if (md.mobile()) {
    $('#pretzel-video').remove()
  }

  // GET GEOLOCATION
  window.getGeoLocation = function getGeoLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction, { timeout: 10000 })
    }

    //Get the latitude and the longitude;
    function successFunction(position) {
      var lat = position.coords.latitude
      var lng = position.coords.longitude
      geocodeLatLng(lat, lng)
    }

    function errorFunction(error) {
      console.error(error)

      if (Cookies.get('lat_lng')) {
        Cookies.remove('lat_lng')
        if (gon.action_params && !(gon.action_params.location || gon.action_params.search_by_map)) {
          // Update if stations page search was made with old lat_lng from cookies
          window.updateStations($('.header-search'))
        }
      }
    }

    function geocodeLatLng(lat, lng) {
      var updateStation = false

      var oldCookie = Cookies.get('lat_lng')

      window.setCookie('lat_lng', [lat, lng].join(','), { path: '/' }, true)

      // Current page search stations
      if (gon.action_params) {
        if (!gon.action_params.location && !gon.action_params.search_by_map) {
          updateStation = true
          if (oldCookie) {
            var old_lat = parseFloat(oldCookie.split(',')[0]),
              old_lng = parseFloat(oldCookie.split(',')[1]),
              precision = parseFloat(gon.coordinates_comparison_precision)
            if (Math.abs(old_lat - lat) <= precision && Math.abs(old_lng - lng) <= precision) {
              updateStation = false
            }
          }

          // If no oldCookie or out of precision - update station
          if (updateStation) {
            gon.action_params['lat'] = lat
            gon.action_params['lng'] = lng
          }
        }
      }

      $.getJSON('/api/geocode', { lat: lat, lng: lng }, function(data) {
        if (data) {
          var seacrh_import = $('.input-geo-search')
          if (!seacrh_import.val()) {
            seacrh_import.val(data.formatted_address)
          }
          if (!seacrh_import.data('lat') && !seacrh_import.data('lng')) {
            $('.input-geo-search').data({ lat: lat, lng: lng })
          }

          if (!$('input[name="locale_city_slug"]').val()) {
            $('input[name="locale_city_slug"]').val(data.city_slug)
          }

          var type = $.grep(data.types, function(n) {
            return $.inArray(n, ['locality', 'country']) !== -1
          })[0]

          // Update landing
          var landing_geo = $('.main-search .input-geo-search')
          landing_geo.val(data.formatted_address)
          landing_geo.data({ lat: lat, lng: lng })
          if (type) {
            landing_geo.data('types', type)
          } else {
            landing_geo.removeData('types')
          }

          // Redirect to stations search
          var stations_search = $('.header-search .input-geo-search')
          if (stations_search.length) {
            if (gon.action_params) {
              var location_param = gon.action_params.location
              var href = window.generateSearchHref($('.input-geo-search:enabled').parents('form'))
            } else {
              location_param = URI(location).search(true).location
              var uri = new URI(),
                search = uri.search(true)
              search.location = data.formatted_address
              href = uri.search(search).readable()
            }
            if (!location_param) {
              stations_search.val(data.formatted_address)
              stations_search.data({ lat: lat, lng: lng })
              if (type) {
                stations_search.data('types', type)
              } else {
                stations_search.removeData('types')
              }

              history.pushState({ href: href }, '', href)
            }

            var gonAction = gon.action_params || {}
            if (updateStation && gonAction['action'] != 'show') {
              window.updateStations($('.input-geo-search:enabled').parents('form'))
            }
          }

          if (!URI(location).search(true).city) {
            $('.input-review-city').val(data.city)

            // Update reviews page after auto geolocation
            var e = $.Event('keypress')
            e.keyCode = 13
            $('.header-search .input-review-city').trigger(e)
          }
        }
      })
    }
  }

  if (gon.get_geo_location) {
    window.getGeoLocation()
  }
  //TOP HEADER

  // Init date search fields
  if (gon.action_params) {
    var real_date = gon.action_params.real_date
    var real_time = gon.action_params.real_time
  } else {
    real_date = URI(location).search(true).real_date
    real_time = URI(location).search(true).real_time
  }
  if (real_date) {
    var selectedDate = new Date(real_date)
    $('.input-real-date-search').datepicker('setDate', selectedDate)
    $('.input-real-date-search').change()
    if (real_time) {
      $('.input-real-time-search').val(real_time)
    }
    $('#station-fast-registration input[name="order[date]"]').datepicker('setDate', selectedDate)
    $('#station-fast-registration input[name="order[date]"]').change()
  }

  $('.input-real-date-search').on('change', function() {
    var $this = $(this)

    // Update certificate stations
    if ($this.hasClass('certificate')) {
      window.refreshCertificateStationsList($(this).closest('form'))
    }

    // Update search results in background if not home page
    if (!$this.hasClass('no-auto-update')) {
      window.updateStations($(this).closest('form'))
    }
  })

  $(document).on('change', '.input-real-time-search', function() {
    var $this = $(this)
    // Update search results in background if not home page
    if (!$this.hasClass('no-auto-update')) {
      window.updateStations($(this).closest('form'))
    }
  })

  GoogleMapsLoader.load(function() {
    // Init location fileds
    $('.input-geo-search')
      .geocomplete({ autoselect: false })
      .bind('geocode:result', function(event, result) {
        var $this = $(this),
          $form = $this.closest('form')
        $this.data('lat', result.geometry.location.lat())
        $this.data('lng', result.geometry.location.lng())
        var types = $.grep(result.types, function(n) {
          return $.inArray(n, ['neighborhood', 'locality', 'country', 'administrative_area_level_1']) !== -1
        })
        if (types.length) {
          var type = types[0]
          if (type == 'administrative_area_level_1') {
            type = 'aal1'
          }
          $this.data('types', type)
        } else {
          $this.removeData('types')
        }
        var city = result.address_components.filter(function(el) {
          return el['types'].toString() === ['locality', 'political'].toString()
        })
        var neighborhood = result.address_components.filter(function(el) {
          return el['types'].toString() === ['neighborhood', 'political'].toString()
        })
        var neighborhood_or_city = neighborhood.length ? neighborhood : city

        if (neighborhood_or_city.length) {
          var city_fix = neighborhood_or_city[0]['short_name']

          if (city_fix == 'Днипро') {
            city_fix = 'Днепр'
          } else if (city_fix == 'Дніпро́') {
            city_fix = 'Дніпро'
          }

          $.ajax({
            url: '/api/front/v1/location/get_slug/',
            type: 'GET',
            data: {
              name: city_fix,
              locale_country: gon.locale_country
            }
          })
            .done(function(response) {
              $form.find('input[name="locale_city_slug"]').val(response.data.slug)
            })
            .always(function() {
              if (gon.action_params) {
                delete gon.action_params.search_by_map
                delete gon.action_params.bounds
              }
              if (!$this.hasClass('no-auto-update')) {
                window.updateStations($form)
              }
              if ($this.data('geocode') == 'wait') {
                $this.trigger('geocodeSubmit')
              }
            })
        } else {
          $form.find('input[name="locale_city_slug"]').val('')
          if (gon.action_params) {
            delete gon.action_params.search_by_map
            delete gon.action_params.bounds
          }
          if (!$this.hasClass('no-auto-update')) {
            window.updateStations($form)
          }
          if ($this.data('geocode') == 'wait') {
            $this.trigger('geocodeSubmit')
          }
        }
        if (gon.action_params) {
          delete gon.action_params.search_by_map
          delete gon.action_params.bounds
        }
      })
      .on('change', function() {
        var $this = $(this),
          $form = $this.closest('form')

        $this.prop('title', $this.val())
        if ($this.val() == '') {
          $form.find('input[name="locale_city_slug"]').val('')
          $.each($('.input-geo-search:enabled'), function(i, e) {
            e.removeAttribute('data-lat')
            e.removeAttribute('data-lng')
            e.removeAttribute('data-types')
          })
          if (gon.action_params) {
            delete gon.action_params['geo_types']
            delete gon.action_params['lat']
            delete gon.action_params['lng']
          }
        }
      })
      .on('keypress', function(e) {
        if (e.keyCode == 13) {
          $(this).trigger('geocode')
        }
      })
  })

  var cache = {}

  function split(val) {
    val = val || ''
    return val.toString().split(/,\s*/)
  }

  function filterServicesSearch(data, input) {
    var serviceIds = split(input.data('serviceIds')),
      problemIds = split(input.data('problemIds')),
      symptomIds = split(input.data('symptomIds'))

    return data.filter(function(item) {
      if (item.category_code == 'services') {
        return serviceIds.indexOf(item.value.toString()) == -1
      } else if (item.category_code == 'problems') {
        return problemIds.indexOf(item.value.toString()) == -1
      } else if (item.category_code == 'symptoms') {
        return symptomIds.indexOf(item.value.toString()) == -1
      } else {
        return true
      }
    })
  }

  gon.selected_services = gon.selected_services || []
  gon.selected_problems = gon.selected_problems || []
  gon.selected_symptoms = gon.selected_symptoms || []

  var services = gon.selected_services,
    problems = gon.selected_problems,
    symptoms = gon.selected_symptoms

  $('.input-quadrature-search').on('keyup', function() {
    this.value = this.value.replace(/[^0-9.]/g, '')

    if ($(this).hasClass('no-auto-update')) {
      return
    }

    window.updateStations($(this).closest('form'))
  })

  $('.input-service-search')
    .searchcomplete({
      search_type: 'service',
      autoFocus: true,
      source: function(request, response) {
        var $this = this.element,
          term = window.extractLast(request.term),
          search_type = this.option('search_type') || 'service',
          search_tab_id = this.option('search_tab_id') || null,
          search_type_url = ''

        cache[search_type] = cache[search_type] || {}

        switch (search_type) {
          case 'service':
          case 'review':
            search_type_url = 'search'
            break
          case 'custom':
            search_type_url = 'custom_search'
            break
          default:
            break
        }

        // Load term suggestions from server
        var geo_address = $this
            .closest('form')
            .find('.input-geo-search')
            .val(),
          locale_country = $this
            .closest('form')
            .find('input[name="locale_country"]')
            .val(),
          url_query = {
            term: term,
            address: geo_address,
            locale_country: locale_country,
            search_tab_id: search_tab_id
          }

        if (gon && gon.action_params && gon.action_params.iframe_client_id) {
          url_query['iframe_client_id'] = gon.action_params.iframe_client_id
        }

        const stationsFilterServiceId = $this.data('stationsFilterServiceId')
        if ( typeof(stationsFilterServiceId) != 'undefined' ){
          url_query['stations_filter_service_id'] = stationsFilterServiceId
        }

        $.getJSON(`/api/services/${search_type_url}`, url_query, function(data) {
          cache[search_type][term] = data
          response(filterServicesSearch(data, $this))
        })
      },
      focus: function() {
        return false
      },
      select: function(event, ui) {
        if (ui.item.category_code == 'stations') {
          var $a = $(this)
            .parents('.ui-front')
            .find('.ui-state-focus > a')
          if ($a.length && event && event.keyCode == 13) {
            window.open($a.attr('href'), '_blank')
          }
          return false
        } else if (ui.item.category_code == 'services') {
          if (services.indexOf(ui.item) == -1) {
            services.push(ui.item)
          }
          var terms = split(this.value)
          terms.pop()
          terms.push(ui.item.label)
          terms.push('')
          this.value = terms.join(', ')
          terms.pop()
          var values = services
            .filter(function(service) {
              return terms.indexOf(service.label) != -1
            })
            .map(function(service) {
              return service.value
            })
            .sort()

          // Save selected service ids

          gon.selected_services = services
          $(this).data('serviceIds', values.join(','))
          $(this).data('serviceSlugs', window.fetch_slugs(services))

          // Update search results in background if not home page
          if (!$(this).hasClass('no-auto-update')) {
            window.updateStations($(this).closest('form'))
          }
        } else if (ui.item.category_code == 'problems') {
          if (problems.indexOf(ui.item) == -1) {
            problems.push(ui.item)
          }
          terms = split(this.value)
          terms.pop()
          terms.push(ui.item.label)
          terms.push('')
          this.value = terms.join(', ')
          terms.pop()
          values = problems
            .filter(function(problem) {
              return terms.indexOf(problem.label) != -1
            })
            .map(function(problem) {
              return problem.value
            })
            .sort()

          // Save selected problems ids
          $(this).data('problemIds', values.join(','))

          // Update search results in background if not home page
          if (!$(this).hasClass('no-auto-update')) {
            window.updateStations($(this).closest('form'))
          }
        } else if (ui.item.category_code == 'symptoms') {
          if (symptoms.indexOf(ui.item) == -1) {
            symptoms.push(ui.item)
          }

          terms = split(this.value)

          terms.pop()
          terms.push(ui.item.label)
          terms.push('')

          this.value = terms.join(', ')

          terms.pop()

          values = symptoms
            .filter(function(symptom) {
              return terms.indexOf(symptom.label) != -1
            })
            .map(function(symptom) {
              return symptom.value
            })
            .sort()

          // Save selected problems ids
          $(this).data('symptomIds', values.join(','))

          // Update search results in background if not home page
          if (!$(this).hasClass('no-auto-update')) {
            window.updateStations($(this).closest('form'))
          }
        }
        return false
      },
      minLength: 0,
      search: function() {
        $(this)
          .parents('.ui-front')
          .toggleClass('ui-loading', true)
      },
      response: function() {
        $(this)
          .parents('.ui-front')
          .toggleClass('ui-loading', false)
      }
    })
    .click(function(e) {
      var $self = $(this)
      if ($self.val().length == 0) {
        $self.searchcomplete({ autoFocus: false })
      } else {
        $self.searchcomplete({ autoFocus: true })
      }
      if (
        $self
          .parents('.ui-front')
          .find('.ui-autobooking')
          .css('display') != 'block' &&
        $self.is(':focus')
      ) {
        $self.searchcomplete('search', window.extractLast(e.target.value))
      }
    })
    .on('keyup', function() {
      var $this = $(this),
        terms = split(this.value),
        toDelete = [],
        values = services
          .filter(function(service) {
            if (terms.indexOf(service.label) == -1) {
              toDelete.push(service)
              return false
            }
            return true
          })
          .map(function(service) {
            return service.value
          })
          .sort()
      if ($this.val().length == 0) {
        $this.searchcomplete({ autoFocus: false })
      } else {
        $this.searchcomplete({ autoFocus: true })
      }
      toDelete.forEach(function(service) {
        services.splice(services.indexOf(service), 1)
      })
      var serviceIds = values.join(',')
      gon.selected_services = services
      if (($this.data('serviceIds') || '') != serviceIds) {
        $this.data('serviceIds', serviceIds)

        // if(!$this.hasClass("no-auto-update") && serviceIds.length != 0) {
        //   window.updateStations($(this).closest('form'));
        // }
      }

      toDelete = []
      values = problems
        .filter(function(problem) {
          if (terms.indexOf(problem.label) == -1) {
            toDelete.push(problem)
            return false
          }
          return true
        })
        .map(function(problem) {
          return problem.value
        })
        .sort()
      toDelete.forEach(function(problem) {
        problems.splice(problems.indexOf(problem), 1)
      })
      var problemIds = values.join(',')
      if (($this.data('problemIds') || '') != problemIds) {
        $this.data('problemIds', problemIds)
        // if(!$this.hasClass("no-auto-update") && problemIds.length != 0) {
        //   window.updateStations($(this).closest('form'));
        // }
      }

      toDelete = []
      values = symptoms
        .filter(function(symptom) {
          if (terms.indexOf(symptom.label) == -1) {
            toDelete.push(symptom)
            return false
          }
          return true
        })
        .map(function(symptom) {
          return symptom.value
        })
        .sort()
      toDelete.forEach(function(symptom) {
        symptoms.splice(symptoms.indexOf(symptom), 1)
      })

      var symptomIds = values.join(',')
      if (($this.data('symptomIds') || '') != symptomIds) {
        $this.data('symptomIds', symptomIds)
      }
    })

  $('.input-place-outside-ukraine-search').on('change', function() {
    var $this = $(this)
    $this
      .parents('form')
      .find('.input-place-search')
      .attr('disabled', $this.is(':checked'))
  })

  $('.input-place-search')
    .searchcomplete({
      autoFocus: true,
      source: function(request, response) {
        var term = window.extractLast(request.term)

        $.getJSON('/api/front/v1/insurances/ewa/search_place/', { term: term }, function(data) {
          response(data.data)
        })
      },
      focus: function() {
        return false
      },
      select: function(event, ui) {
        $(this).val(ui.item.label)
        $(this).data('place-id', ui.item.value)
        return false
      },
      minLength: 2,
      search: function() {
        $(this)
          .parents('.ui-front')
          .toggleClass('ui-loading', true)
      },
      response: function() {
        $(this)
          .parents('.ui-front')
          .toggleClass('ui-loading', false)
      }
    })
    .click(function() {
      if ($(this).val().length != 0) {
        $(this).val('')
      }
    })

  $('.input-vehicle-type-search')
    .searchcomplete({
      autoFocus: true,
      minLength: 0,
      source: function(request, response) {
        $.getJSON('/api/front/v1/insurances/ewa/vehicle_types/', {}, function(data) {
          response(data.data)
        })
      },
      focus: function() {
        return false
      },
      select: function(event, ui) {
        $(this).val(ui.item.label)
        $(this).data('type', ui.item.value)
        return false
      },
      search: function() {
        $(this)
          .parents('.ui-front')
          .toggleClass('ui-loading', true)
      },
      response: function() {
        $(this)
          .parents('.ui-front')
          .toggleClass('ui-loading', false)
      }
    })
    .click(function(e) {
      var $self = $(this)
      if ($self.val().length != 0) {
        $self.val('')
        $self.data('type', '')
        $('.input-vehicle-category-search').val('')
        $('.input-vehicle-category-search').data('type', '')
      }
      $self.searchcomplete({ autoFocus: false })
      $self.searchcomplete('search', e.target.value)
    })

  $('.input-vehicle-category-search')
    .searchcomplete({
      autoFocus: true,
      minLength: 0,
      source: function(request, response) {
        var $this = this.element,
          selected_type = $this
            .parents('form')
            .find('.input-vehicle-type-search')
            .data('type')
        if (selected_type) {
          $.getJSON('/api/front/v1/insurances/ewa/vehicle_types/', { type: selected_type }, function(data) {
            response(data.data[0].categories)
          })
        } else {
          return false
        }
      },
      focus: function() {
        return false
      },
      select: function(event, ui) {
        $(this).val(ui.item.label)
        $(this).data('category', ui.item.value)
        return false
      },
      search: function() {
        var selected_type = $(this)
          .parents('form')
          .find('.input-vehicle-type-search')
          .data('type')
        if (selected_type) {
          $(this)
            .parents('.ui-front')
            .toggleClass('ui-loading', true)
        }
      },
      response: function() {
        $(this)
          .parents('.ui-front')
          .toggleClass('ui-loading', false)
      }
    })
    .click(function(e) {
      var $self = $(this)
      if ($self.val().length != 0) {
        $self.val('')
      }
      $self.searchcomplete({ autoFocus: false })
      $self.searchcomplete('search', e.target.value)
    })

  $('#sort_by').on('change', function() {
    gon.action_params.sort_by = $(this).val()
    window.updateStations($('form.header-search'))
  })

  // STATION AND SEARCH PAGE MODAL SEARCH
  $('#modal-search-form').on('submit', function(e) {
    var form = $(this)
    e.preventDefault()
    window.updateStations(form)
  })
})

$('.stations .wrapper, #gmap').on('click', 'div.btn-qo', function(e) {
  e.preventDefault()
  var qo_form = $(this)
    .parents('.station')
    .find('.quick-order-form')
  qo_form.fadeIn(400)
  $(this)
    .parents('.quick-order')
    .hide()
})

$('.stations .wrapper').on('focus', '.quick-order-form input[type="tel"]', function() {
  if (md.mobile()) {
    $('.filters').css('visibility', 'hidden')
  }
})
$('.stations .wrapper').on('blur', '.quick-order-form input[type="tel"]', function() {
  if (md.mobile()) {
    $('.filters').css('visibility', 'visible')
  }
})

$('#callModal .quick-order-form')
  .on('focus', 'input[type="tel"]', function() {
    if (md.mobile()) {
      $('#callModal').animate({ 'margin-top': -$('#callModal .time.row').outerHeight() })
    }
  })
  .on('blur', 'input[type="tel"]', function() {
    if (md.mobile()) {
      $('#callModal').animate({ 'margin-top': 0 })
    }
  })

// Gallery on station page
$(function() {
  $('.photos').each(function() {
    var $pic = $(this),
      getItems = function() {
        var items = []
        $pic.find('a').each(function() {
          var $href = $(this).attr('href'),
            $size = $(this)
              .data('size')
              .split('x'),
            $width = $size[0],
            $height = $size[1]

          var item = {
            src: $href,
            w: $width,
            h: $height,
            el: $(this).closest('figure')
          }

          items.push(item)
        })
        return items
      }

    var items = getItems()

    var $pswp = $('.pswp')[0]
    $pic.on('click', 'a', function(event) {
      event.preventDefault()

      var $index = $(this).data('index')
      var options = {
        index: $index,
        bgOpacity: 0.85,
        getThumbBoundsFn: function(index) {
          // See Options->getThumbBoundsFn section of docs for more info
          var thumbnail = items[index].el[0],
            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
            rect = thumbnail.getBoundingClientRect()
          return { x: rect.left, y: rect.top + pageYScroll, w: rect.width }
        }
      }

      // Initialize PhotoSwipe
      var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options)
      lightBox.init()
    })
  })

  $('.more').on('click', function(e) {
    e.preventDefault()
    $('body')
      .find('.medium-line .col')
      .removeClass('hidden')
    $(this).remove()
    return false
  })
})

// Tooltips for review on station page
$(function() {
  $('.order-review .details > .star').tooltip({
    html: true,
    title: function() {
      return $(this)
        .next('.tooltip-content')
        .html()
    }
  })
})

$(function() {
  $('.btn-review').click(function() {
    $(this).addClass('hidden')
  })
})

// Menu on station page
$(function() {
  // Create the dropdown base
  $('<select />')
    .appendTo('#sub-navbar')
    .addClass('form-control visible-xs-block visible-sm-block')

  // Create default option "Go to..."
  $('<option />', {
    selected: 'selected',
    value: '',
    text: I18n.t('js.go_to') + '...'
  }).appendTo('#sub-navbar select')

  // Populate dropdown with menu items
  $('#sub-navbar a').each(function() {
    var el = $(this)
    $('<option />', {
      value: el.attr('href'),
      text: el.text()
    }).appendTo('#sub-navbar select')
  })

  $('#sub-navbar select').change(function() {
    window.location = $(this)
      .find('option:selected')
      .val()
  })
})

// For profile page
$('.shared-header .header-search').css('right', $('#profile-nav .navbar-nav').outerWidth() || 0)
$('.shared-header .navbar-nav-general').css('right', $('.navbar-nav-profile').outerWidth() || 0)
$('.inner-header .header-search').css('right', $('#profile-nav .navbar-nav').outerWidth() || 0)
$('.reviews-header .header-search').css('right', $('#profile-nav .navbar-nav').outerWidth() || 0)

// Translate validations
$(function() {
  var currentValidationMessage = {
    typeMismatch: {
      defaultMessage: I18n.t('js.validityMessages.typeMismatch.defaultMessage'),
      email: I18n.t('js.validityMessages.typeMismatch.email'),
      url: I18n.t('js.validityMessages.typeMismatch.url')
    },
    badInput: {
      defaultMessage: I18n.t('js.validityMessages.badInput.defaultMessage'),
      number: I18n.t('js.validityMessages.badInput.number'),
      date: I18n.t('js.validityMessages.badInput.date'),
      time: I18n.t('js.validityMessages.badInput.time'),
      range: I18n.t('js.validityMessages.badInput.range'),
      month: I18n.t('js.validityMessages.badInput.month'),
      'datetime-local': I18n.t('js.validityMessages.badInput.datetime_local')
    },
    rangeUnderflow: {
      defaultMessage: I18n.t('js.validityMessages.rangeUnderflow.defaultMessage'),
      date: I18n.t('js.validityMessages.rangeUnderflow.date'),
      time: I18n.t('js.validityMessages.rangeUnderflow.time'),
      'datetime-local': I18n.t('js.validityMessages.rangeUnderflow.datetime_local'),
      month: I18n.t('js.validityMessages.rangeUnderflow.month')
    },
    rangeOverflow: {
      defaultMessage: I18n.t('js.validityMessages.rangeOverflow.defaultMessage'),
      date: I18n.t('js.validityMessages.rangeOverflow.date'),
      time: I18n.t('js.validityMessages.rangeOverflow.time'),
      'datetime-local': I18n.t('js.validityMessages.rangeOverflow.datetime_local'),
      month: I18n.t('js.validityMessages.rangeOverflow.month')
    },
    stepMismatch: I18n.t('js.validityMessages.stepMismatch'),
    tooLong: I18n.t('js.validityMessages.tooLong'),
    tooShort: I18n.t('js.validityMessages.tooShort'),
    patternMismatch: I18n.t('js.validityMessages.patternMismatch'),
    valueMissing: {
      defaultMessage: I18n.t('js.validityMessages.valueMissing.defaultMessage'),
      checkbox: I18n.t('js.validityMessages.valueMissing.checkbox'),
      select: I18n.t('js.validityMessages.valueMissing.select'),
      radio: I18n.t('js.validityMessages.valueMissing.radio')
    }
  }

  var noValidate = function() {
    return !noValidate.types[this.types]
  }
  noValidate.types = {
    hidden: 1,
    image: 1,
    button: 1,
    reset: 1,
    submit: 1
  }

  var lReg = /</g
  var gReg = />/g

  var getMessageFromObj = function(message, elem) {
    if (message && typeof message !== 'string') {
      message =
        message[$.prop(elem, 'type')] || message[(elem.nodeName || '').toLowerCase()] || message['defaultMessage']
    }
    return message || ''
  }

  var replaceValidationplaceholder = function(elem, message) {
    if (message && message.indexOf('{%') != -1) {
      ['value', 'min', 'max', 'maxlength', 'minlength'].forEach(function(attr) {
        if (message.indexOf('{%' + attr) === -1) {
          return
        }
        var val = $.prop(elem, attr) || $.attr(elem, attr) || '' || ''
        val = '' + val

        message = message.replace('{%' + attr + '}', val.replace(lReg, '&lt;').replace(gReg, '&gt;'))
        if ('value' == attr) {
          message = message.replace('{%valueLen}', val.length)
        }
      })
    }
    return message
  }

  var createValidationMessage = function(elem, name) {
    var message = getMessageFromObj(currentValidationMessage[name], elem)
    if (!message && name == 'badInput') {
      message = getMessageFromObj(currentValidationMessage.typeMismatch, elem)
    }
    if (!message && name == 'typeMismatch') {
      message = getMessageFromObj(currentValidationMessage.badInput, elem)
    }
    message = replaceValidationplaceholder(elem, message, name)

    return message || ''
  }

  var onInvalid = function(event) {
    if (noValidate) {
      var message = ''
      $.each(event.target.validity, function(name, prop) {
        if (name == 'valid' || !prop) {
          return
        }
        message = createValidationMessage(event.target, name)
        if (message) {
          return false
        }
      })
      event.target.setCustomValidity(message || '')
    }
  }

  var onChange = function(event) {
    event.target.setCustomValidity('')
  }

  $('input, textarea, select').on('invalid', onInvalid)

  $('input, textarea, select').on('change', onChange)

  $(document).on('DOMNodeInsertedIntoDocument', 'input, textarea, select', function(event) {
    $(event.target).on('invalid', onInvalid)
    $(event.target).on('change', onChange)
  })

  //  Profile user orders active tab
  var profileOrdersTabs = $('#orders .nav.nav-tabs').find('li')
  if (profileOrdersTabs.length) {
    profileOrdersTabs.each(function() {
      if (
        $(this)
          .find('a')
          .attr('href') == window.location.hash
      ) {
        $(this).addClass('active')
      }
    })
    if (!$('#orders .nav.nav-tabs').find('li.active').length) {
      profileOrdersTabs.first().addClass('active')
    }
  }

  //  Profile user orders active tab content
  var profileOrdersTabsContent = $('#orders .tab-content').find('.tab-pane')
  if (profileOrdersTabsContent.length) {
    profileOrdersTabsContent.each(function() {
      if ('#' + $(this).attr('id') == window.location.hash) {
        $(this).addClass('active')
      }
    })
    if (!$('#orders .tab-content').find('.tab-pane.active').length) {
      profileOrdersTabsContent.first().addClass('active')
    }
  }
})

$(function() {
  $.each($('.form-disabled').find('input'), function(index, value) {
    $(value).attr('disabled', 'disabled')
  })
})
