/*global I18n*/
I18n.translations || (I18n.translations = {})
I18n.translations['ua'] = I18n.extend(I18n.translations['ua'] || {}, gon.translations.ua)
I18n.translations['ru'] = I18n.extend(I18n.translations['ru'] || {}, gon.translations.ru)
I18n.translations['en'] = I18n.extend(I18n.translations['en'] || {}, gon.translations.en)
I18n.translations['pl'] = I18n.extend(I18n.translations['pl'] || {}, gon.translations.pl)
I18n.translations['de'] = I18n.extend(I18n.translations['de'] || {}, gon.translations.de)
I18n.translations['uk'] = I18n.extend(I18n.translations['uk'] || {}, gon.translations.uk)
I18n.translations['tr'] = I18n.extend(I18n.translations['tr'] || {}, gon.translations.tr)
I18n.translations['nl'] = I18n.extend(I18n.translations['nl'] || {}, gon.translations.nl)
