/*global $*/
(function() {
  'use strict'

  $('.login-form')
    .validator({
      focus: false
    })
    .on('submit', function(e) {
      return !e.isDefaultPrevented()
    })

  $('.register_tab_link').click(function() {
    //get selected href
    var href = $(this).attr('href')

    //set all nav tabs to inactive
    $('.login-tabs li').removeClass('active')

    //get all nav tabs matching the href and set to active
    $('.login-tabs li a[href="' + href + '"]')
      .closest('li')
      .addClass('active')
  })

  $('#login-modal').on('show.bs.modal', function(e) {
    var button = $(e.relatedTarget) // Button that triggered the modal
    var recipient = button.data('showed-screen') || '.__signin' // Extract info from data-* attributes
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this)
    $('.second').addClass('hide')
    modal.find(recipient).removeClass('hide')

    if (!!e.relatedTarget && !!e.relatedTarget.href) {
      var hash = e.relatedTarget.href.split('#')[1]
      if (typeof hash != 'undefined' && hash != '') {
        var selector = 'a[href="#' + hash + '"]'
        $(selector, '.login-tabs').tab('show')
      }
    }
  })

  $('.password-recovery-form')
    .bind('ajax:before', function() {
      var $form = $(this)
      $form.find('.help-block').empty()
    })
    .bind('ajax:success', function(event, data) {
      var $form = $(this)
      $form.find('.help-block').text(data.data)
      $form.find('.form-control, button[type="submit"]').toggleClass('hidden', true)
      $form.find('.btn-reset').toggleClass('hidden', false)
    })
    .bind('ajax:error', function(event, status) {
      if (status.responseJSON && status.responseJSON['errors']) {
        var $form = $(this)
        $form.find('.help-block').text(status.responseJSON['errors'])
      }
    })
    .find('button.btn-reset')
    .click(function() {
      var $form = $(this).closest('form')
      $form.find('.form-control, button[type="submit"]').toggleClass('hidden', false)
      $form.find('.btn-reset').toggleClass('hidden', true)
      $form.find('.help-block').empty()
    })

  $('.login-form ')
    .find('input[name="agree"]')
    .on('change', function() {
      var $form = $(this).closest('form')
      $form
        .closest('.tab-pane')
        .find('.login-btn a')
        .toggleClass('disabled', !$(this).is(':checked'))
    })

  $('#modalSignUpLink').on('click', function() {
    $('#login-modal > .__signin').addClass('flipOutY')

    setTimeout(function() {
      $('#login-modal > .__signup')
        .removeClass('hide')
        .addClass('flipInY')
    }, 750)

    setTimeout(function() {
      $('#login-modal > .__signin')
        .addClass('hide')
        .removeClass('flipOutY')
      $('#login-modal > .__signup').removeClass('flipInY')
    }, 1750)

    var email = $('.login-form input[name="user[email]"]').val()
    $('.password-recovery-form input[name="user[email]"]').val(email)
  })

  $('#modalSignInLink').on('click', function() {
    $('#login-modal > .__signup').addClass('flipOutY')

    setTimeout(function() {
      $('#login-modal > .__signin')
        .removeClass('hide')
        .addClass('flipInY')
    }, 750)

    setTimeout(function() {
      $('#login-modal > .__signup')
        .addClass('hide')
        .removeClass('flipOutY')
      $('#login-modal > .__signin').removeClass('flipInY')
    }, 1750)
  })
})()