/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import Rails from 'rails-ujs'
// Rails.start()

import {} from 'jquery-ujs'

import '../src/javascript/i18n/translations'
import '../src/javascript/i18n/jquery-ui-datapicker'
import '../src/javascript/i18n/moment'

import 'jquery-touchswipe'
import 'javascripts/i18n/live_translations'
import 'bootstrap'
import 'nprogress'
import 'geocomplete'

import 'javascripts/vendor/cp'

import 'smartbanner.js'
import 'bootstrap-validator'

import 'javascripts/cookies'
import 'javascripts/filters'
import 'javascripts/search'
import 'javascripts/search/styles'
import 'javascripts/footer'
import 'javascripts/global'
import 'javascripts/login'
import 'javascripts/landing'
import 'javascripts/notifications'
import 'javascripts/promo_video'
import 'javascripts/google_analytics'
import 'javascripts/facebook_pixel'

import 'javascripts/admin/notify'
import 'javascripts/admin/notify-metro'
import 'javascripts/common'
import 'javascripts/flash'
import 'javascripts/react_auth'

import $ from 'jquery'
export { $ }
