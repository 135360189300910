/*global gon $ I18n*/
import Notifications from 'javascripts/admin/session_notifications'

$(function() {
  if (gon.flash) {
    gon.flash.forEach(function(notification) {
      Notifications.admin_notify(I18n.t('datatables.js.attention'), notification[1], flash_notification_class(notification[0]), true)
    })
  }

  function flash_notification_class(type) {
    switch (type) {
      case 'info':
        return 'info'
      case 'notice':
        return 'info'
      case 'success':
        return 'success'
      case 'error':
        return 'error'
      default:
        return 'cool'
    }
  }
})