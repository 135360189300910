/*global gon $ I18n*/

if (typeof require === 'function') {
  var URI = require('urijs')
}

$(function() {
  $('.main-search .geolocation').on('click', function() {
    window.getGeoLocation()
  })

  $('#landing-search-form').on('submit', function(e) {
    e.preventDefault()

    var $self = $(this),
      servicesSearchInput = $self.find('input.input-service-search'),
      searchText = servicesSearchInput.val(),
      searchGeo = $self.find('input.input-geo-search').val(),
      searchGeoLat = $self.find('input.input-geo-search').data('lat'),
      searchGeoLng = $self.find('input.input-geo-search').data('lng'),
      services = gon.selected_services,
      problems = gon.selected_problems,
      symptoms = gon.selected_symptoms,
      styles = gon.selected_styles

    var timeout
    function showError(error) {
      $self
        .find('.field.service')
        .popover({ content: error || '' })
        .popover('show')
      clearTimeout(timeout)
      timeout = setTimeout(function() {
        $self.find('.field.service').popover('hide')
      }, 3000)
    }
    // If inputed text but no selected any value
    if (
      searchText &&
      (!services || !services.length) &&
      (!problems || !problems.length) &&
      (!symptoms || !symptoms.length) &&
      (!styles || !styles.length)
    ) {
      showError(I18n.t('landing.js.choose_error'))
      return
    }

    // If no inputed text and no geo searched
    if (
      !searchGeo &&
      (!services || !services.length) &&
      (!problems || !problems.length) &&
      (!symptoms || !symptoms.length) &&
      (!styles || !styles.length)
    ) {
      showError(I18n.t('landing.js.all_choose_error'))
      return
    }

    if (searchGeo && !searchGeoLat && !searchGeoLng) {
      $self.find('input.input-geo-search').data('geocode', 'wait')
      $self.find('input.input-geo-search').trigger('geocode')
      return
    }

    var href = window.generateSearchHref(this)

    if ([...services, ...problems, ...symptoms].length === 1) {
      [...services, ...problems, ...symptoms].forEach(function(service) {
        if (service.insurance && gon.country_setting.country_code === 'UA') {
          var locale_country = $self.find('input[name="locale_country"]').val()
          if (service.insurance_type == 'kasko') {
            var path = locale_country + '/insurances/kasko'
          } else {
            var path = locale_country + '/insurances/osago'
          }
          href = URI()
            .search(false)
            .path(path)
            .normalize()
            .href()
        }
      })
    }

    document.location.href = href
  })

  $('#landing-search-form .input-geo-search').bind('geocodeSubmit', function() {
    $('#landing-search-form').submit()
  })

  $('#landing-search-form .input-geo-search').keypress(function(e) {
    if (e.keyCode == 13) {
      e.preventDefault()
      e.stopPropagation()
      $('#landing-search-form').submit()
    } else {
      $(this).removeData('lat')
      $(this).removeData('lng')
    }
  })

  $('.input-real-date-search').datepicker({
    showOtherMonths: true,
    selectOtherMonths: true,
    minDate: new Date(),
    prevText: '',
    nextText: '',
    dateFormat: 'd M'
  })

  $('#landing-search-select input[name=search-options]:radio:checked').click()
  landing_search_select()

  $('#landing-search-select input[name=search-options]:radio').change(landing_search_select)

  function landing_search_select() {
    var $this = $('#landing-search-select input[name=search-options]:radio:checked'),
      $stations_form = $('#landing-search-form'),
      $reviews_form = $('#landing-review-search-form'),
      $remote_insurance_form = $('#landing-insurance-search-form')
    switch ($this.data('search-type')) {
      case 'services':
        $stations_form.toggleClass('hidden', false)
        $reviews_form.toggleClass('hidden', true)
        $remote_insurance_form.toggleClass('hidden', true)
        $stations_form.find('.input-service-search').attr('placeholder', I18n.t('datatables.js.global_search.service'))
        $stations_form
          .find('.input-service-search')
          .val('')
          .data('serviceIds', '')
          .searchcomplete('option', 'search_type', 'service')
        break
      case 'review':
        $stations_form.toggleClass('hidden', true)
        $reviews_form.toggleClass('hidden', false)
        $remote_insurance_form.toggleClass('hidden', true)
        break
      case 'remote_insurance':
        $remote_insurance_form.toggleClass('hidden', false)
        $stations_form.toggleClass('hidden', true)
        $reviews_form.toggleClass('hidden', true)
        break
      case 'custom':
        $stations_form.toggleClass('hidden', false)
        $reviews_form.toggleClass('hidden', true)
        $remote_insurance_form.toggleClass('hidden', true)
        $stations_form.find('.input-service-search').attr('placeholder', $this.data('placeholder'))
        $stations_form
          .find('.input-service-search')
          .val('')
          .data('serviceIds', $this.data('search'))
          .searchcomplete('option', 'search_type', $this.data('search-type'))
          .searchcomplete('option', 'search_tab_id', $this.data('search-tab-id'))
        break
      default:
        break
    }
  }

  $('#landing-modal-search-type-select').change(function() {
    var $this = $(this),
      $stations_form = $('#modal-search-form'),
      $stations_form_fields = $stations_form.find(
        '.input-service-search, .input-geo-search, .input-car-name-search, .input-real-date-search, .input-real-time-search'
      ),
      $reviews_form = $this.closest('.modal').find('.reviews-search-modal-form'),
      $remote_insurance_form = $this.closest('.modal').find('#modal-insurance-search-form')
    switch ($this.val()) {
      case 'service':
        $stations_form.toggleClass('hidden', false)
        $reviews_form.toggleClass('hidden', true)
        $remote_insurance_form.toggleClass('hidden', true)

        $stations_form
          .find('.input-service-search')
          .attr('placeholder', I18n.t('datatables.js.global_search.service'))
          .val('')
          .data('serviceIds', '')
          .searchcomplete('option', 'search_type', 'service')
        $stations_form_fields
          .attr('disabled', false)
          .closest('.form-group')
          .toggleClass('hidden', false)
        break
      case 'review':
        $stations_form.toggleClass('hidden', true)
        $reviews_form.toggleClass('hidden', false)
        $remote_insurance_form.toggleClass('hidden', true)

        $reviews_form
          .find(
            '.input-review-sort, .input-review-city, .input-review-car, input[name="car_model"], input[name="car_brand"], .input-review-service'
          )
          .attr('disabled', true)
          .closest('.form-group')
          .toggleClass('hidden', true)
        break
      case 'remote_insurance':
        $remote_insurance_form.toggleClass('hidden', false)
        $stations_form.toggleClass('hidden', true)
        $reviews_form.toggleClass('hidden', true)
        break
      case 'custom':
        $stations_form.toggleClass('hidden', false)
        $reviews_form.toggleClass('hidden', true)
        $remote_insurance_form.toggleClass('hidden', true)

        $stations_form
          .find('.input-service-search')
          .attr('placeholder', $this.find(':selected').data('placeholder'))
          .val('')
          .data('serviceIds', $this.data('search'))
          .searchcomplete('option', 'search_type', $this.find(':selected').data('search-type'))
          .searchcomplete('option', 'search_tab_id', $this.find(':selected').data('search-tab-id'))

        $stations_form_fields
          .attr('disabled', true)
          .closest('.form-group')
          .toggleClass('hidden', true)
        $stations_form
          .find('.input-service-search, .input-geo-search')
          .attr('disabled', false)
          .closest('.form-group')
          .toggleClass('hidden', false)
        break
      default:
        break
    }
  })

  $('#landing-review-search-form').on('submit', function(event) {
    event.preventDefault()

    var $self = $(this),
      uri = new URI()
    uri.pathname(gon.locale_country + '/reviews').href()
    var search = uri.search(true)

    var search_text = $self.find('input[name="search_text"]').val()
    if (search_text) {
      search['search_text'] = search_text
    }

    document.location.href = uri.search(search).readable()
    return false
  })

  $('#landing-insurance-search-form, #modal-insurance-search-form').on('submit', function(event) {
    event.preventDefault()

    var $self = $(this),
      uri = new URI($self.attr('action'))
    var search = uri.search(true)

    var vehicleLicensePlate = $self.find('.input-vehicle-license-plate-number-search').val()
    if (vehicleLicensePlate) {
      search['vehicleLicensePlate'] = vehicleLicensePlate.toUpperCase()
    }

    var vehicleCategory = $self.find('.input-vehicle-category-search').data('category')
    if (vehicleCategory) {
      search['vehicleCategory'] = vehicleCategory
    }

    var outsideUkraine = $self.find('.input-place-outside-ukraine-search').is(':checked')
    if (outsideUkraine) {
      search['outsideUkraine'] = outsideUkraine
    } else {
      var registrationPlace = $self.find('.input-place-search').data('place-id')
      if (registrationPlace) {
        search['registrationPlace'] = registrationPlace
      }
    }

    document.location.href = uri.search(search).readable()
    return false
  })

  // Enable sliders swiping
  $('.carousel-inner').swipe({
    //Generic swipe handler for all directions
    swipeLeft: function() {
      $(this)
        .parent()
        .carousel('next')
    },

    swipeRight: function() {
      $(this)
        .parent()
        .carousel('prev')
    }
  })

  function initCarousel(objectName) {
    var $carouselSelector = $(`#landing-carousel-${objectName}-mobile`)

    $carouselSelector.append(`<ol class="carousel-indicators ${objectName}-carousel-indicators visible-xs-block"></ol>`)

    var $objectIndicator = $(`.${objectName} .carousel-indicators`)

    $carouselSelector
      .find('.carousel-inner')
      .children('.item')
      .each(function(index) {
        if (index === 0)
          $objectIndicator.append(
            '<li data-target="#' + $carouselSelector.attr('id') + '" data-slide-to="' + index + '" class="active"></li>'
          )
        else
          $objectIndicator.append(
            '<li data-target="#' + $carouselSelector.attr('id') + '" data-slide-to="' + index + '"></li>'
          )
      })
  }

  // http://ryanringler.com/blog/2014/08/24/fixed-height-carousel-for-twitter-bootstrap
  function carouselNormalization(id) {
    var items = $('#' + id + ' .item'), // grab all the slides
      heights = [], // array to store heights
      tallest // tallest slide

    if (items.length) {
      /* eslint-disable no-inner-declarations*/
      function normalizeHeights() {
        items.each(function() {
          heights.push($(this).height()) // add each slide's height
        }) // to the array

        tallest = Math.max.apply(null, heights) // find the largest height

        items.each(function() {
          $(this).css('min-height', tallest + 'px') // set each slide's minimum
        }) // height to the largest
      }
      /* eslint-enable no-inner-declarations*/

      normalizeHeights()

      $(window).on('resize orientationchange', function() {
        (tallest = 0), (heights.length = 0) // reset the variables

        items.each(function() {
          $(this).css('min-height', '0') // reset each slide's height
        })

        normalizeHeights() // run it again
      })
    }
  }

  initCarousel('comments')
  initCarousel('news')
  initCarousel('projects')
  initCarousel('insurers')

  carouselNormalization('landing-carousel-promo')
  carouselNormalization('landing-carousel-promo-mobile')
  carouselNormalization('landing-carousel-comments')
  carouselNormalization('landing-carousel-news')
  carouselNormalization('landing-carousel-news-mobile')
  carouselNormalization('landing-carousel-comments-mobile')
  carouselNormalization('landing-carousel-projects')
  carouselNormalization('landing-carousel-projects-mobile')
  carouselNormalization('landing-carousel-insurers')
  carouselNormalization('landing-carousel-insurers-mobile')

  $('.home-page .comments blockquote').on('click', '.hellip', function() {
    var $blockquote = $(this).closest('blockquote')
    $blockquote.find('.truncated').toggleClass('hidden')
    $blockquote.find('.untruncated').toggleClass('hidden')
  })

  if (gon.country_setting.important_modal_open) {
    $('#promoImportant').modal('show')
  }
})
