/* global $ I18n*/
function admin_notify(title, text, style, autoHide, position) {
  var icon = admin_notify_icon(style)

  $.notify(
    {
      title: title,
      text: text,
      image: '<i class="' + icon + '"></i>'
    },
    {
      style: 'metro',
      className: style,
      globalPosition: position,
      showAnimation: 'show',
      showDuration: 0,
      hideDuration: 0,
      autoHideDelay: 4000,
      autoHide: style != 'error' && autoHide,
      clickToHide: true
    }
  )
}

function confirm_remove_services(title, text, style, autoHide, position) {
  var icon = admin_notify_icon(style)

  $.notify(
    {
      title: title,
      text:
        text +
        '<br><a class="btn btn-sm btn-default remove-slot-services">Yes</a> <a class="btn btn-sm btn-danger no">No</a>',
      image: '<i class="' + icon + '"></i>'
    },
    {
      style: 'metro',
      className: style,
      globalPosition: position,
      showAnimation: 'show',
      showDuration: 0,
      hideDuration: 0,
      autoHideDelay: 4000,
      autoHide: style != 'error' && autoHide,
      clickToHide: true
    }
  )

  $(document).on('click', '.btn.remove-slot-services', function() {
    var vals = [],
      form = $('#station-services'),
      station_id = form.data('station-id')
    $('.activate-service-checkbox:checked').each(function() {
      vals.push(
        $(this)
          .closest('tr')
          .find('.service-id')
          .val()
      )
    })
    $.ajax({
      type: 'POST',
      url: '/station_admin/stations/' + station_id + '/remove_slots_services',
      dataType: 'JSON',
      data: {
        station_id: station_id,
        selected_services: vals
      },
      success: function() {
        form.submit()
      }
    })
  })
}

var confirmations_counter = 0
function admin_confirmation(title, text, style, autoHide, confirm, unconfirm, position) {
  confirmations_counter++
  var confirmations_counter_style = 'notifyjs-confirmation-' + confirmations_counter,
    icon = admin_notify_icon(style)

  $.notify(
    {
      title: title,
      text:
        text +
        '<div class="clearfix"></div><br><a class="btn btn-sm btn-default yes">Yes</a> <a class="btn btn-sm btn-danger no">No</a>',
      image: '<i class="' + icon + '"></i>'
    },
    {
      style: 'metro',
      className: style + ' ' + confirmations_counter_style,
      globalPosition: position,
      showAnimation: 'show',
      showDuration: 0,
      hideDuration: 0,
      autoHideDelay: 4000,
      autoHide: autoHide,
      clickToHide: false
    }
  )

  $(function() {
    //listen for click events from this style
    $(document).on('click', '.' + confirmations_counter_style + ' .no:not(.disabled)', function() {
      var $self = $(this)
      $self.attr('disabled', true)
      $self.toggleClass('disabled', true)
      unconfirm(
        function() {
          $self.trigger('notify-hide')
        },
        function() {
          $self.attr('disabled', false)
          $self.toggleClass('disabled', false)
        }
      )
    })
    $(document).on('click', '.' + confirmations_counter_style + ' .yes:not(.disabled)', function() {
      var $self = $(this)
      $self.attr('disabled', true)
      $self.toggleClass('disabled', true)
      confirm(
        function() {
          $self.trigger('notify-hide')
        },
        function() {
          $self.attr('disabled', false)
          $self.toggleClass('disabled', false)
        }
      )
    })
  })
}

function admin_notify_icon(style) {
  if (style == 'error') {
    return 'fa fa-exclamation'
  } else if (style == 'warning') {
    return 'fa fa-warning'
  } else if (style == 'success') {
    return 'fa fa-check'
  } else if (style == 'info') {
    return 'fa fa-question'
  } else {
    return 'fa fa-circle-o'
  }
}

function errors_notify(error) {
  if (error.response && (error.response.status == 422 || error.response.status == 429 || error.response.status == 403)) {
    error.response.json().then(function(data) {
      if (data.errors) {
        data.errors.forEach(function(element) {
          admin_notify(I18n.t('datatables.js.attention'), element.detail, 'error', true)
        })
      } else {
        admin_notify(I18n.t('datatables.js.attention'), error.toString(), 'error', true)
      }
    })
  } else {
    admin_notify(I18n.t('datatables.js.attention'), error.toString(), 'error', true)
  }
  console.error(error)
}

function errors_notify_jquery(error) {
  if (error.status && (error.status == 422 || error.status == 429 || error.status == 403)) {
    if (error.responseJSON && error.responseJSON.errors) {
      error.responseJSON.errors.forEach(function(element) {
        admin_notify(I18n.t('datatables.js.attention'), element.detail, 'error', true)
      })
    } else {
      admin_notify(I18n.t('datatables.js.attention'), error.responseText, 'error', true)
    }
  } else {
    admin_notify(I18n.t('datatables.js.attention'), error.responseText, 'error', true)
  }
  console.error(error)
}

if (typeof module === 'object' && module.exports) {
  module.exports = {
    errors_notify_jquery,
    errors_notify,
    admin_notify,
    admin_confirmation,
    confirm_remove_services
  }
}
