/*global gon $ ga*/

if (typeof ga === 'function') {
  $(document).on('click', '.ga-registration-btn:enabled:not(.disabled)', function() {
    ga('send', 'event', 'button-registration', 'click')
  })

  $(document).on('click', '.ga-login:enabled:not(.disabled)', function() {
    ga('send', 'event', 'button-login', 'click')
  })

  $(document).on('click', '.ga-review:enabled:not(.disabled)', function() {
    ga('send', 'event', 'button-review', 'click')
  })

  if (gon.country_setting.country_code == 'DE') {
    $(document).on('click', '#stations-list .ga-quick-order:enabled:not(.disabled)', function() {
      ga('send', 'event', 'DE – Contact Step 1', 'DE – Sidebar Contact Klick', 'DE – Sidebar')
    })

    $(document).on('click', '#stations-list .ga-call-me:enabled:not(.disabled)', function() {
      ga('send', 'event', 'DE – Call Step 2', 'DE – Sidebar Call Klick', 'DE – Sidebar')
    })

    $(document).on('click', '#gmap .ga-quick-order:enabled:not(.disabled)', function() {
      ga('send', 'event', 'DE – Contact Step 1', 'DE – Map Contact Klick', 'DE – Map-Bereich')
    })

    $(document).on('click', '#gmap .ga-call-me:enabled:not(.disabled)', function() {
      ga('send', 'event', 'DE – Call Step 2', 'DE – Map Call Klick', 'DE – Map-Bereich')
    })
  }
}