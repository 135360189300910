/*global gon $*/

if (typeof require === 'function') {
  var GoogleMapsLoader = require('google-maps'),
    NProgress = require('nprogress'),
    LazyLoad = require('vanilla-lazyload'),
    Cookies = require('js-cookie')
}

/*
  Setup GoogleMapsLoader
*/
GoogleMapsLoader.KEY = gon.google_maps_loader_key
GoogleMapsLoader.LIBRARIES = ['geometry', 'places']
GoogleMapsLoader.LANGUAGE = gon.locale == 'ua' ? 'uk' : gon.locale || 'ru'
GoogleMapsLoader.REGION = gon.country_setting.country_code
GoogleMapsLoader.VERSION = '3.40'
if (gon.google_maps_loader_key) {
  GoogleMapsLoader.load(function() {})
}

/*
  Configure NProgress
*/
NProgress.configure({ showSpinner: false })

/*
  Run image LazyLoad
*/
new LazyLoad({ elements_selector: 'img, div[data-src*="http"], input[data-src*="http"]' })

/*
  Allow to select text
*/
$('body').removeClass('noselect')

/*
  Responsive and banners adjustment
*/
function ajustFixedElements() {
  $('html').css('margin-top', $('.js_smartbanner').outerHeight())

  let header_height = $('body > header:visible').outerHeight() || 0
  $('body.home-page, body.static').find('> main').css(
    'padding-top',
    $('body > header .read_privacy:visible').outerHeight() || 0
  )
  $('body:not(.home-page, .static) > main').css('padding-top', header_height)

  let breadcrumbsOuterHeight = $('#breadcrumbs').outerHeight()
  $('.landing-header.landing-top').first().css('top', breadcrumbsOuterHeight)

  $('body.search main > .stations').css('margin-top', breadcrumbsOuterHeight)

  $('body.search main > .map').css('margin-top', breadcrumbsOuterHeight)
  $('body.search main > .map').css('top', header_height)

  $('body.search main > .search-by-map').css('margin-top', breadcrumbsOuterHeight + 13)

  $('body.search main > .stations #stations-list').css(
    'padding-top',
    $('body.search main > .stations .filters').outerHeight()
  )
}

window.onload = function() {
  ajustFixedElements()
}

$('.js_smartbanner').on('DOMNodeRemoved', ajustFixedElements)

if (!Cookies.get('cookies_confirmed')) {
  $('.read_privacy').slideDown(200, ajustFixedElements)
}

$(document).on('click', '#confirm-privacy-block', function() {
  $('.read_privacy').slideUp(200, ajustFixedElements)
  Cookies.set('cookies_confirmed', '1', { expires: 361 })
})

// https://css-tricks.com/snippets/jquery/done-resizing-event/
var resizeTimer
$(window).on('resize', function() {
  clearTimeout(resizeTimer)
  resizeTimer = setTimeout(function() {
    ajustFixedElements()
  }, 250)
})
