/*global $*/

if (typeof require === 'function') {
  var Cookies = require('js-cookie')
}

$(function() {
  window.setCookie = function(key, value, params, necessary) {
    if (!Cookies.get('preferences_cookies_disable_by_user') || necessary) {
      var params_with_expire = params
      params_with_expire.expires = 361
      Cookies.set(key, value, params_with_expire)
    }
  }
})