/*global gon NProgress stationsSearch $ order_service_slugs moment*/

if (typeof require === 'function') {
  var Cookies = require('js-cookie'),
    URI = require('urijs'),
    URITemplate = require('urijs/src/URITemplate')
}

// Content update and back/forward button handler for search page
if (gon.action_params) {
  window.onpopstate = function() {
    NProgress.start()
    stationsSearch(window.location.href)
  }
}

window.updateStations = function(form) {
  var href = window.generateSearchHref(form),
      services = gon.selected_services,
      problems = gon.selected_problems,
      symptoms = gon.selected_symptoms

  if ([...services, ...problems, ...symptoms].length === 1) {
    [...services, ...problems, ...symptoms].forEach(function(service) {
      if (service.insurance && gon.country_setting.country_code === 'UA') {
        var locale_country = form.find('input[name="locale_country"]').val()
          if (service.insurance_type == 'kasko') {
            var path = locale_country + '/insurances/kasko'
          } else {
            var path = locale_country + '/insurances/osago'
          }
          href = URI()
            .search(false)
            .path(path)
            .normalize()
            .href()
        document.location.href = href
      }
    })
  }

  if (gon.ajax_search) {
    let url
    if (gon && gon.action_params && gon.action_params.iframe_client_id) {
      url = new URL(href)
      url.searchParams.set('iframe_client_id', gon.action_params.iframe_client_id)
      href = url.toString()
    }
    if (typeof(gon.stationsFilterServiceId) != 'undefined'){
      url = new URL(href)
      url.searchParams.set('stations_filter_service_id', gon.stationsFilterServiceId)
      href = url.toString()
    }
    NProgress.start()
    history.pushState({ href: href }, 'Stations', href)
    stationsSearch(href)
  } else {
    document.location.href = href
  }
}

function generateSearchPath(search) {
  search = search || {}

  let separator
  if (
    search.distance ||
    search.lat ||
    search.lng ||
    search.geo_types ||
    search.service_synonym ||
    search.styles ||
    search.date ||
    search.time ||
    search.additional_services ||
    search.body_type ||
    search.networked ||
    search.hourly_rate ||
    search.sort_by ||
    search.search_by_map ||
    search.bounds ||
    search.page
  ) {
    separator = 'abs'
  }

  if (search.city_slug) search.city_slug = 'c-' + search.city_slug
  if (search.services) search.services = 's-' + search.services
  if (search.styles) search.styles = 'st-' + search.styles
  if (search.brand) search.brand = 'm-' + search.brand
  if (search.quadrature) search.quadrature = 'q-' + search.quadrature
  if (search.distance) search.distance = 'd-' + search.distance
  if (search.lat) search.lat = 'lat-' + search.lat
  if (search.lng) search.lng = 'lng-' + search.lng
  if (search.geo_types) search.geo_types = 'gt-' + search.geo_types
  if (search.service_synonym) search.service_synonym = 'p-' + search.service_synonym
  if (search.date) search.date = 'rd-' + search.date
  if (search.time) search.time = 'rt-' + search.time
  if (search.additional_services) search.additional_services = 'as-' + search.additional_services
  if (search.body_type) search.body_type = 'bt-' + search.body_type
  if (search.networked) search.networked = 'n-' + search.networked
  if (search.hourly_rate) search.hourly_rate = 'hr-' + search.hourly_rate
  if (search.sort_by) search.sort_by = 'sb-' + search.sort_by
  if (search.search_by_map) search.search_by_map = 'sbm'

  if (search.ne_lat) search.ne_lat = 'ne_lat-' + search.ne_lat
  if (search.ne_lng) search.ne_lng = 'ne_lng-' + search.ne_lng
  if (search.sw_lat) search.sw_lat = 'sw_lat-' + search.sw_lat
  if (search.sw_lng) search.sw_lng = 'sw_lng-' + search.sw_lng

  if (search.page) search.page = 'pg-' + search.page

  return URITemplate(
    '{locale_country}/' +
    '{station_type}/' +
    '{city}/' +
    '{services}/' +
    '{styles_before_separator}/' +
    '{brand}/' +
    '{service_synonym_slugs_before_separator}/' +
    '{separator}/' +
    '{distance}/' +
    '{lat}/' +
    '{lng}/' +
    '{geo_types}/' +
    '{date}/' +
    '{time}/' +
    '{service_synonym_slugs_after_separator}/' +
    '{styles_after_separator}/' +
    '{quadrature}/' +
    '{additional_services}/' +
    '{body_type}/' +
    '{networked}/' +
    '{hourly_rate}/' +
    '{sort_by}/' +
    '{search_by_map}/' +
    '{ne_lat}/' +
    '{ne_lng}/' +
    '{sw_lat}/' +
    '{sw_lng}/' +
    '{page}'
    )
    .expand({
      station_type: search.station_type,
      city: search.landing_service_search ? '' : search.city_slug,
      services: search.services,
      brand: search.brand,
      separator: separator,
      distance: search.distance,
      lat: search.lat,
      lng: search.lng,
      geo_types: search.geo_types,
      service_synonym_slugs_before_separator: search.service_synonym_slugs_after_separator
        ? ''
        : search.service_synonym,
      service_synonym_slugs_after_separator: search.service_synonym_slugs_after_separator ? search.service_synonym : '',
      date: search.date,
      time: search.time,
      additional_services: search.additional_services,
      body_type: search.body_type,
      networked: search.networked,
      hourly_rate: search.hourly_rate,
      sort_by: search.sort_by,
      search_by_map: search.search_by_map,
      ne_lat: search.ne_lat,
      ne_lng: search.ne_lng,
      sw_lat: search.sw_lat,
      sw_lng: search.sw_lng,
      page: search.page,
      locale_country: search.locale_country,
      quadrature: search.quadrature,
      styles_before_separator: search.styles_after_separator ? '' : search.styles,
      styles_after_separator: search.styles_after_separator ? search.styles : ''
    })
    .normalize()
    .toString()
}

// Generate search href
window.generateSearchHref = function(parentForm) {
  var search = {},
    $this = $(parentForm),
    services = gon.selected_services,
    problems = gon.selected_problems,
    symptoms = gon.selected_symptoms || [],
    styles = gon.selected_styles,
    service_synonyms = $.merge($.merge([], problems), symptoms),
    quadrature = $this.find('input.input-quadrature-search').val()

  search['landing_service_search']  = services.length == 1 && services[0]['landing']

  if (quadrature) {
    search['quadrature'] = quadrature
  }

  if (styles && $this.find('input.input-style-search:enabled').length) {
    search['styles'] = window.fetch_slugs(styles)

    search['styles_after_separator'] = styles.length > 1
  }

  if (services && $this.find('input.input-service-search:enabled').length) {
    search['services'] = window.fetch_slugs(services)
  }

  if (service_synonyms && $this.find('input.input-service-search:enabled').length) {
    search['service_synonym'] = window.fetch_slugs(service_synonyms)

    // Check the service_synonym for type 'synonym'. If there is - exclude from google's indexing.
    search['service_synonym_slugs_after_separator'] = service_synonyms.find(function(service_synonym) {
      return service_synonym['synonym_type'] === 'symptom'
    })
    search['service_synonym_slugs_after_separator'] =
      search['service_synonym_slugs_after_separator'] || service_synonyms.length > 1
  }

  var lat = false
  var lng = false
  var geo_types = false
  var city = false

  if (gon.action_params) {
    if (gon.action_params.search_by_map) {
      search['search_by_map'] = true
      search['ne_lat'] = gon.action_params.bounds.ne_lat
      search['ne_lng'] = gon.action_params.bounds.ne_lng
      search['sw_lat'] = gon.action_params.bounds.sw_lat
      search['sw_lng'] = gon.action_params.bounds.sw_lng
    } else {
      lat = $this.find('input.input-geo-search:enabled').data('lat')
      if (lat) {
        search['lat'] = lat
      } else {
        delete gon.action_params['lat']
      }

      lng = $this.find('input.input-geo-search:enabled').data('lng')
      if (lng) {
        search['lng'] = lng
      } else {
        delete gon.action_params['lng']
      }

      geo_types = $this.find('input.input-geo-search:enabled').data('types')
      if (geo_types) {
        search['geo_types'] = geo_types
      } else {
        delete gon.action_params['geo_types']
      }

      city = $this.find('input[name="locale_city_slug"]').val()
      if (city) {
        search['city_slug'] = city
      }
    }
  } else {
    lat = $this.find('input.input-geo-search:enabled').data('lat')
    if (lat) {
      search['lat'] = lat
    }
    lng = $this.find('input.input-geo-search:enabled').data('lng')
    if (lng) {
      search['lng'] = lng
    }
    geo_types = $this.find('input.input-geo-search:enabled').data('types')
    if (geo_types) {
      search['geo_types'] = geo_types
    }
    city = $this.find('input[name="locale_city_slug"]').val()
    if (city) {
      search['city_slug'] = city
    }
  }

  var brand_input = $this.find('input.input-car-name-search:enabled'),
    brand = brand_input.length ? brand_input.data('carBrandSlug') : false
  if (brand) {
    search['brand'] = brand
  }

  var date_input = $this.find('input.input-real-date-search:enabled'),
    date = date_input.length ? date_input.datepicker('getDate') : false
  if (date && moment(date).isValid()) {
    search['date'] = moment(date).format('YYYY-MM-DD')
  }

  var time = $this.find('.input-real-time-search:enabled').val()
  if (time && moment(time, 'HH:mm').isValid()) {
    search['time'] = time
  }

  var search_params = gon.action_params || {}
  if (search_params.additional_service_ids) {
    search['additional_services'] = search_params.additional_service_ids.split(',').join('_')
  }
  if (search_params.body_types) {
    search['body_type'] = search_params.body_types.split(',').join('_')
  }
  if (search_params.marker_slugs) {
    search['station_type'] = search_params.marker_slugs
  }
  if (search_params.networked) {
    search['networked'] = search_params.networked
  }

  var radius_search_enabled = $this.find('input[name="use_radius_in_search"]').val()

  if (
    radius_search_enabled == 'true' &&
    search['geo_types'] != 'neighborhood' &&
    search['geo_types'] != 'country' &&
    search['geo_types'] != 'aal1' &&
    search['search_by_map'] != true
  ) {
    if (
      search_params.distance &&
      search_params['geo_types'] != 'neighborhood' &&
      search_params['geo_types'] != 'country' &&
      search_params['geo_types'] != 'aal1' &&
      search['search_by_map'] != true
    ) {
      search['distance'] = search_params.distance
    } else if (Cookies.get('search_distance')) {
      search['distance'] = Cookies.get('search_distance')
    }
  }

  if (search_params.hourly_rate) {
    search['hourly_rate'] = search_params.hourly_rate.split(',').join('_')
  }
  if (search_params.sort_by) {
    search['sort_by'] = search_params.sort_by
  }

  gon.action_params = $.extend(gon.action_params, search)

  search['locale_country'] = $this.find('input[name="locale_country"]').val()

  const stationsFilterServiceId = gon.stationsFilterServiceId
  if ( typeof(stationsFilterServiceId) != 'undefined' && stationsFilterServiceId != '' )
    search['stations_filter_service_id'] = stationsFilterServiceId

  var path = generateSearchPath(search)
  return URI()
    .search(false)
    .path(path)
    .normalize()
    .href()
}

$(function() {
  $('#apply-filters').on('click', function() {
    var additional_filters = {}
    additional_filters.additional_service_ids = $.map($('.additional-service-checkbox:checked'), function(elem) {
      return $(elem).data('id')
    }).join(',')
    additional_filters.body_types = $.map($('.body-type-checkbox:checked'), function(elem) {
      return $(elem).data('id')
    }).join(',')
    additional_filters.marker_slugs = $.map($('.marker-checkbox:checked'), function(elem) {
      return $(elem).data('slug')
    }).join('_')
    additional_filters.networked = $('input[name="networked"]:checked').val()
    additional_filters.hourly_rate = $('#dist-slider-double').attr('value')

    if (window.slider && window.slider.isEnabled()) {
      window.setCookie('search_distance', window.slider.getValue(), { path: '/' })

      gon.action_params['distance'] = window.slider.getValue()
    }

    if (window.map && gon.action_params && gon.action_params.search_by_map) {
      var ne = window.map.getBounds().getNorthEast()
      var sw = window.map.getBounds().getSouthWest()

      gon.action_params['search_by_map'] = true
      gon.action_params['bounds'] = { ne_lat: ne.lat(), ne_lng: ne.lng(), sw_lat: sw.lat(), sw_lng: sw.lng() }
    }

    gon.action_params = $.extend(gon.action_params, additional_filters)

    window.updateStations($('.header-search'))
  })
})

$('.filters').on('mousewheel', function(event) {
  var $this = $(this)
  event.stopPropagation()
  var scrollTop = $this.scrollTop(),
    scrollHeight = $this.prop('scrollHeight'),
    clientHeight = $this.prop('clientHeight'),
    delta = event.originalEvent.wheelDelta || -1 * event.originalEvent.detail || -1 * event.originalEvent.deltaY,
    deltaY = 0
  if (event.type === 'wheel') {
    var ratio = $this.height() / $(window).height()
    deltaY = event.originalEvent.deltaY * ratio
  }
  if ((delta > 0 && scrollTop + deltaY <= 0) || (delta < 0 && scrollTop + deltaY >= scrollHeight - clientHeight)) {
    event.preventDefault()
    if (deltaY) $this.scrollTop(scrollTop + deltaY)
  }
})

$('.filters').on('touchstart', function() {
  $('body').css('overflow-y', 'hidden')
})
$('.filters').on('touchend', function() {
  $('body').css('overflow-y', 'auto')
})

$('.search-filters').on('click', function() {
  var $breadcrumbs = $('#breadcrumbs'),
    $stations = $('.stations'),
    $arrow = $('.arrow'),
    stationsListIsShown = $('.list').hasClass('__showed')

  if (!stationsListIsShown) {
    $breadcrumbs.hide()
    $stations.css('marginTop', '0px')
    $arrow.css('marginTop', '133px')
  } else {
    $breadcrumbs.show()
    $stations.css('marginTop', $('#breadcrumbs').outerHeight())
  }
})

$('.filters').on('change', 'input[type=checkbox]', function() {
  $(this)
    .parent()
    .toggleClass('active')
})

$(window).on('resize', function() {
  var $this = $('.filters')
  if ($this.length) {
    $this.css('max-height', $(window).height() - $this.offset().top + window.scrollY)
    $this.width($this.parent().width())
  }
})

$('.filters .btn-filters').on('click', function() {
  $('.filters').css('max-height', $(window).height() - $('.filters').offset().top + window.scrollY)
  if ($('#stations-list').length) {
    $('#stations-list').css('min-height', $(window).height() - $('#stations-list').offset().top)
  }
})

$('input[name="networked"]').change(function() {
  $('.networked-filter label').toggleClass('activated', false)
  $('label[for="' + $(this).attr('id') + '"]').toggleClass('activated', true)
})

window.fetch_slugs = function(arr) {
  return $.map(
    arr.sort(function(a, b) {
      return a['value'] - b['value']
    }),
    function(val) {
      return val['slug']
    }
  ).join('_')
}
