$("#promo_video").on('click', function() {
  if (this.paused) {
    this.play();
    $(this).animate({opacity: 1}, 500);
  } else {
    this.pause();
    $(this).animate({opacity: 0.6}, 500);
  }
});

$('[data-control="video"]').on('click', function(event) {
  var $video = $(this);
  var video_el = $video.find('video').get(0);
  if (video_el.paused) {
    video_el.play();
  } else {
    video_el.pause();
  }
  $(this).closest('.video').toggleClass('video_play', !video_el.paused);
});

$('#promoImportant').on('hide.bs.modal', function (event) {
  var $modal = $(this);
  var $video = $modal.find('.video');

  var video_el = $video.find('video').get(0);
  if (video_el) {
    video_el.pause();
  }
  $video.toggleClass('video_play', false);
})

$('#promoImportant').on('shown.bs.modal', function (event) {
  var $modal = $(this);
  var $video = $modal.find('.video');

  var video_el = $video.find('video').get(0);
  if (video_el) {
    video_el.play();
  }
  $video.toggleClass('video_play', true);
})
